import React, { Component } from 'react'
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
//import SimpleReactFooter from "simple-react-footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faDiscord,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import logo from './logo.png';


export default class footer extends Component {
  render() {
    return (
      
      

      
          
     


 
         
      <div class="container">
      <div class="row">
          <div class="col-12">
              <div class="line"></div>
              <div class="second2">
      <a href="https://discord.gg/N97vBtApQq"
        className="facebook social">
        <FontAwesomeIcon icon={faDiscord} size="3x" />
      </a>
      
      <a href="https://www.twitter.com/BoredFrenchieIC" className="twitter social">
        <FontAwesomeIcon icon={faTwitter} size="3x" />
      </a>            
      <a href="https://www.instagram.com/bfic.nft"
        className="instagram social">
        <FontAwesomeIcon icon={faInstagram} size="3x" />
      </a>         
          
          
              </div>

              <div class="secondlogo">
              <img src={logo} width="100" height="100" />
              </div>


              <div class="secondcopy">
              <p class="copyright">Bored Frenchie Island Club © 2022</p>
              </div>

          </div>
      </div>
      </div>





    )
  }
}
