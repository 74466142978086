import { Height } from '@mui/icons-material';
import React, { Component } from 'react'
import { FaCaretRight,FaCaretLeft } from "react-icons/fa";



export default class Roadmap extends Component {
  render() {
    return (

<div className="tablebox">
        <table className="table1">
        <tr>
          <th className="tabletitle" colspan="5" >Roadmap</th>
          
        </tr>
        <tr>
          <td className="tablec1">Build up DOA to boost the long-term development of the project. Betting game running each weeks for our discord members with attractive mystery gifts to be won!! 
</td>
          <td className="tablec2"><FaCaretRight /> </td>
          <td rowspan="6" className="midline"> 
          </td>     
          <td colspan="2" className="per" >10%</td>
          
        </tr>
        <tr>
          <td className="per" colspan="2" style={{textAlign: "right"}}>20%</td>
          
          
          <td className="tablec2"><FaCaretLeft /></td>
                  <td className="tablec1">$BFIC token will be introduced and launched & 10x BoredFrenchie will be airdropped to random BoredFrenchie holders.
</td>
        </tr>
        <tr>
          <td className="tablec1">A BFIC MERCH STORE will be launched. There will be special discounts for all BoredFrenchie Holder and 25% of all sale profit will be dedicated to funding for BFIC’s community function. 
</td>
          <td className="tablec2"><FaCaretRight /></td>
          <td colspan="2" className="per">50%</td>
          
        </tr>
        <tr>
          <td className="per" colspan="2" style={{textAlign: "right"}}>80%</td>
          <td className="tablec2"><FaCaretLeft /></td>
          <td className="tablec1">We will expand our island by purchasing a land in Decentraland to ensure our spot in the metaverse. There will also be cross-collaborations with other trusted NFT projects that based on collective decision of the team and our community.
</td>
        </tr>
        <tr>
          <td className="tablec1">Lilac Frenchie will QUIT his JOB and focus on the evolution of BFIC!!! THE FRENCHIE RACE in Decentraland is initiated. The team will focuses on establishing a public and private P2E Dog Racing Betting Game on the Metaverse land that we bought. This Game will allow everyone to play with $BFIC, while BoredFrenchie holders and the community will share lifetime profits. There will also be a Grand metaverse event to celebrate BFIC’s success!!!!
</td>
          <td className="tablec2"><FaCaretRight /></td>
          <td className="per" colspan="2">100%</td>
        </tr>
        
        
      </table>



<div className='phase'>
<p className='phaset'>Phase 1</p>
<p className='phasec'>The BFIC Team is going to be putting aside 10% of profit for future project needs. We will continue our growth and have BFIC stand apart from the rest! BFIC Merch Store will be opening and will have T-Shirts, Hats, Posters and etc.  
</p>
<p className='phaset'>Phase 2</p>
<p className='phasec'>We will be expanding our island by getting land in the metaverse to ensure our spot in the metaverse! We will host events for holders and so much more. We are excited to explore the meta with the entire community! Maybe some collabs will also be happening within the Metaverse! By Minting and Holding a BFIC NFT grants various benefits. Our team wants the very best for our community and to take care of our holders. Our plan is to collaborate with the best upcoming NFT launches, to find projects that may have exclusive whitelists for some Bored Frenchie Holders. We will hold votes from time to time to see what projects you guys would like to see us collaborate with.</p>

<p className='phaset'>Phase 3</p>
<p className='phasec'>We will start exploring how we can get involved in the Metaverse and we will start working on bringing BoredFrenchie in 3D. The project by BFIC is to establish a P2E Dog Racing Game in the Metaverse. But our community has a say on the evolution of such project.</p>

<p className='phaset'>Phase 4</p>
<p className='phasec'>We will start working on BFIC V2 (which BFIC V1 holders will be guaranteed with exclusive benefits) and also our P2E dog racing game to make it available in our island on Metaverse.</p>

<p className='phaset'>Roadmap 2.0</p>
<p className='phasec'>Roadmap 2.0 will be built by our community, and it all depends on what you BoredFrenchie holders want! For this reason we will be building and revealing this roadmap directly after launch, including ideas from our entire community! We will take this project to the next level! We are so excited for all of you to join this journey and create one of the most if not the most unique project out there. The BFIC are here to take-over the NFT industry, so join our movement and become a holder of one of the most well designed projects out there!</p>


</div>


<div className='phase'>
<h2 className="accordiontitle">BFIC Community’s UTILITIES</h2>

<p className='phaset'>1. A United Communal Model</p>
<p className='phasec'>To honor the true values of decentralization, BFIC will be run by the community and to establish a multisig and voting mechanism for BoredFrenchie holders’ participation in the development and decision-making of the community. All verified BoredFrenchie holders will have a dedicated Discord channel, from where they will have the biggest say in which direction the project is going. This channel also grants holders benefits for other projects. Besides helping each other and sharing new investment opportunities, we let the community make the call on what direction to take, by holding polls, whenever there is a decision to be made.
</p>
<p className='phaset'>2. Collaboration with the BEST upcoming projects</p>
<p className='phasec'>Our plan is to collaborate with the best upcoming trusted NFT development teams, to find projects that may have exclusive whitelists for BoredFrenchie Holders! These collaborations will provide early access opportunities for our community. We will hold votes from time to time to see what projects you guys would like to see us collaborate with!
</p>
<p className='phaset'>3. FREE MINT</p>
<p className='phasec'>BFIC v2 Free Mint Pass will be distributed to all BoredFrenchie holders (Paying Buyers) over the course of 30 days after launch is successful AND BFIC v2 Guaranteed Mint Pass will be distributed to all BoredFrenchie holders.
</p>
<p className='phaset'>4. Exclusive Metaverse wearables/avatars</p>
<p className='phasec'>Upon BFIC V2 launches, we will have our BFIC exclusive Metaverse character ready and officially starts on our Metaverse project voted by the community.
</p>
<p className='phaset'>5. Staking</p>
<p className='phasec'>By staking, every BoredFrenchie holders will be rewarded with $BFIC. The use cases for this is entry into our upcoming P2E game. 
</p>
</div>


      </div>



    )
  }
}
