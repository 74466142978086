import React, { Component } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export default class Accor extends Component {
  render() {
    return (
       <div>
           
       </div>

       
    )
  }
}
