import React, { Component } from 'react';
import { Navbar,Nav } from 'react-bootstrap';
import NavbarToggle from 'react-bootstrap/esm/NavbarToggle';
import logo from './logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faFacebook,
  faDiscord,
  faTwitter,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";


//import { LinkContainer } from 'react-router-bootstrap';
import { Link } from "react-router-dom";
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";

export default class header extends Component {
  render() {
    return (
        
        <div className='navtop'>
            <Navbar bg ="dark" variant="dark" sticky='top' expand="lg">
            <img src={logo} width="40" height="40" />
            <Navbar.Brand>
            Bored Frenchie Island Club
            </Navbar.Brand>
            
        <NavbarToggle />
        <Navbar.Collapse className='right-aligned'>
            <Nav>
            
            {/*<Nav.Link  className='box-top'>Opensea</Nav.Link>  
            <Nav.Link  className='box-top'>Mint</Nav.Link>*/}

            <Nav.Link href="https://mint.bfic.club" className='box-top'>Mint</Nav.Link>
            <Nav.Link href="#story"  className='box-top'>Story</Nav.Link>
            <Nav.Link href="#roadmap"  className='box-top'>Roadmap</Nav.Link>
            <Nav.Link href="#team" className='box-top'>Team</Nav.Link>
            <Nav.Link href="#faq" className='box-top'>FAQ</Nav.Link>
            
           
            

            </Nav>
        </Navbar.Collapse>

            </Navbar>


            <Nav >
            <div className='floatMenu'>
               
            
            <Nav.Link href="https://discord.gg/N97vBtApQq" ><FontAwesomeIcon icon={faDiscord} size="2x"/></Nav.Link>  
            <Nav.Link href="https://www.instagram.com/bfic.nft" ><FontAwesomeIcon icon={faInstagram} size="2x"/></Nav.Link>  
            <Nav.Link href="https://twitter.com/BoredFrenchieIC" > <FontAwesomeIcon icon={faTwitter} size="2x"/></Nav.Link> 
               
            
               
            </div>
            </Nav>

            

      </div>
     
    )
  }
}
