import React, { Component } from 'react'
import blue from './images/blue.jpg';
import lilac from './images/lilac.png';
import merle from './images/merle.png';
import sable from './images/sable.png';


export default class team extends Component {
  render() {
    return (

        <div class="rowt">
        <div class="columnt">
        <img src={blue} class="t-img"/>
            <h2>Blue Frenchie</h2>
            <h2>Co-Founder</h2>
    <p class="t-pos">BFIC PROJECT MANAGER</p>
    <p class="t-dec">Who bring together the best people in each of the key areas and founded this remarkable project. Entrepreneur owning several businesses and has exclusive idea to initiate and establish a new venture in this project. Highly active in blockchain and cryptocurrencies since 2015.</p>
    </div>
        <div class="columnt">
        <img src={lilac} class="t-img" />
        <h2>Lilac Frenchie</h2>
        <h2>Co-Founder</h2>
        <p class="t-pos">BFIC DESIGNER</p>
        <p class="t-dec">Who are ambitious and excited to contribute his masterpiece to this project. Degree holder with many years experiences in 2D & 3D character design and UI/UX.</p>
        </div>
        
        <div class="columnt">
        <img src={sable} class="t-img"/>
        <h2>Merle Frenchie</h2>
        <h2>Co-Founder</h2>
          <p class="t-pos">BFIC SOFTWARE ENGINEER</p>
          <p class="t-dec">In charge with the development of software, web 3 and solidity. Involved in the blockchain and cryptocurrencies since 2018.</p>
        </div>

        <div class="columnt">
        <img src={merle} class="t-img"/>
        <h2>Sable Frenchie</h2>
        <h2>Co-Founder</h2>
         <p class="t-pos">BFIC COMMUNITY ADMINISTRATOR</p>
         <p class="t-dec">Part-time MBA degree student.</p>
        </div>



      </div>

    )
  }
}
