import Footer from "./components/footer/footer";
import Header from  "./components/Header/Header";
import Faq from  "./components/faq/faq";
import banner from './images/banner02.jpg';
import { FaqData } from './FaqData';
import React, { Component } from 'react'
import './app.css'
import "./bootstrap.min.css"
import Accor from "./Accor"
import Story from "./Story"
import Roadmap from "./Roadmap"
import Team from "./team"
//import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Mint from "./Mint"
import Home from "./home"
//import Index from "./"
//import Accordion from './Accordion';




class App extends Component {
  
  render() {
    return (
      <div >

<Header />




<div className='contentbanner'>

<img src={banner}  />
</div>



<div id={'story'}>
<h1 className="accordiontitle">The Story</h1> 
<h1 className="storyline"></h1> 
<Story />
</div>


<div id={'roadmap'}>
<Roadmap />
</div>


<div id={'team'}>
<h1 className="accordiontitle">Team</h1> 
<Team />
</div>


<div id={'faq'}>
      <h1 className="accordiontitle">FAQs</h1>
      <div className="accordion">
        {FaqData.map(({ title, content }) => (
          <Faq title={title} content={content} />
        ))}
      </div>
    </div>







<Footer />


      </div>



      
    )
  }
}









export default App;
