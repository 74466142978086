export const FaqData = [
    {
      title: 'Why Bored Frenchie Island Club?',
      content: `Bored Frenchie Island Club is a collection of algorithmically generated BFIC minted as NFTs on the Ethereum blockchain. The 1st generation of 8888 BoredFrenchie holders will get exclusive access to the ecosystem. `
    },
    //{

    //  `When u buy a Bored Frenchie, you are gaining membership access to a community whose benefits and offerings will increase over time. 
    //  Our goal is to achieve a united community via DOA system which allow Frenchie Holders to participate in the future evolution and expansion of the project. 
    //  `

     // title: 'When is the launch?',
    //  content: `We will announce the exact date on our Twitter and Discord.`
    //`Bored Frenchie Island Club is a collection of algorithmically generated BFIC minted as NFTs on the Ethereum blockchain. 8888 BoredFrenchie holders will get exclusive access to the ecosystem. `
    //},




    {
      title: 'What are the benefits?',
      content:  `When u buy a Bored Frenchie, you are gaining membership access to a community whose benefits and offerings will increase over time. 
        Our goal is to achieve a united community via DOA system which allow Frenchie Holders to participate in the future evolution and expansion of the project. 
        `
      },


    //{
 //     title: 'How do I mint?',
 //     content: `You can visit our website at https://bfic.club/ to connect your Metamask wallet.
 //     Metamask can be downloaded from the App store, Google Play, and Chrome Extension on IOS, Android and PC devices respectively.`
 //   },
    {
      title: 'How many Bored Frenchie are there?',
      content: `We are proud to boast a diverse collection of 8888 unique BFIC NFTs.`
    },
//    {
//      title: 'How long will it take for my NFT to be revealed after minting?',
//      content: `The image will be announced within 72 hours after sold out.`
//    },
 //   {
 //     title: 'Will there be a presale?',
 //     content: `You must be signed up to our Whitelist to take part in our Presale. We will be holding our Presale very soon, as our Whitelist spaces are limited, please keep an eye on our Discord channel to receive the newest updates on how to sign up.`
 //   },
    //{
    //  title: 'Will there be a presale?',
   //   content: `Step-By-Step Guide
   //   Here are the steps on what you need to do to get your Fortune Friends Club NFT.
   //   1) Sign up for Metamask or Coinbase Wallet and download the extension on your internet browser.
  //    2）Make sure you have enough Ethereum in your wallet to cover the total cost including gas fees.
  //    3）On mint day, there will be a Connect button at the top of our website to connect to your wallet.
   //   4）Click on the mint button and you will be prompted to sign for your transaction. There will be a fee associated with every transaction related to gas prices.
   //   5）Once you have made your purchase, your Bao NFTs will appear in your wallet and on Opensea!`
  //  },
    {
      title: 'Who is the owner of the NFT?',
      content: `Once you own a particular BFIC, Bored Frenchie Island Club Team grants you a license to use copy and display the purchased art worldwide, whether for personal or commercial use. Ownership of the NFT is mediated entirely by the Smart Contract and the Ethereum Network.`
    }




  ];