import React, { Component } from 'react'

export default class Story extends Component {
  render() {
    return (
      <div className='story'>

      Our beautiful remote and exotic island is ruled by a Bored Frenchies tribe that survived from a shipwreck many years ago.<br></br><br></br>
      
      Trace back to the beginning, the Bored Frenchies tribe originated from a bunch of French bulldog puppy which mutated into a human-like Frenchies due to a leakage of an unknown pastel-coloured gas washed onto the shores from the wrecked ship. The DNA genetic mutation had transformed them into brilliant smart Bored Frenchies that have very quick feet. <br></br>
      <br></br>
      The BFIC island’s trade system was introduced into the tribe upon the discovery of a valuable coins from one of the hidden caves in the island. <br></br>
      <br></br>
      Since the population is growing, the tribe foresee the importance of having a united and strong community with great support system to protect themselves from possible invasion of outsiders.</div>
    )
  }
}
